import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { message } from "antd"; // <-- Import message from antd

// Export to Excel
export const exportToExcel = (data, filename = "report") => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
};

// Export to CSV
export const exportToCSV = (data, filename = "report") => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const csv = XLSX.utils.sheet_to_csv(worksheet);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${filename}.csv`;
    a.click();
    URL.revokeObjectURL(url);
};

// Helper to convert image URL to Base64
const getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;

        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };

        img.onerror = () => {
            reject(new Error(`Failed to load image from URL: ${url}`));
        };
    });
};

// Export to PDF
export const exportToPDF = (
    columns,
    data,
    filename = "report",
    title = "Student Details Report",
    titleColor = [0, 0, 255],
    logoPath = "/img/go-green.png",
    startDate = null, // Optional startDate
    endDate = null    // Optional endDate
) => {
    const columnWidth = 80;
    const totalWidth = columns.length * columnWidth;
    const pageWidth = Math.max(totalWidth, 595);

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: [pageWidth, 842],
    });

    const formatDateTime = (dateStr, time = "00:00:00") => {
        if (!dateStr) return ""; // Safeguard against null/undefined dateStr
        const date = new Date(dateStr);
        const [hours, minutes, seconds] = time.split(":");
        date.setHours(hours, minutes, seconds);
        return date
            .toLocaleString("en-GB", {
                weekday: "short",
                day: "2-digit",
                month: "short",
                year: "numeric",
            })
            .replace(",", "")
            .replace(/\b\d\b/, "0$&");
    };

    // Handle Date Range
    const formattedStartDate = startDate
        ? formatDateTime(startDate, "00:00:00")
        : null;
    const formattedEndDate = endDate
        ? formatDateTime(endDate, "23:59:59")
        : null;

    // Get the current date and time for report generation
    const now = new Date();
    const reportDate = now.toLocaleDateString();
    const reportTime = now.toLocaleTimeString();
    const dateTimeString = `Generated on ${reportDate} ${reportTime}`;

    // Convert the logo image to Base64 and proceed with the PDF generation
    getBase64ImageFromURL(logoPath)
        .then((base64String) => {
            const pageWidthInPoints = doc.internal.pageSize.getWidth();
            const logoX = pageWidthInPoints - 250;
            doc.addImage(base64String, "PNG", logoX, 45, 170, 45);

            // Title and date display
            doc.setFontSize(22);
            doc.setTextColor(...titleColor);
            doc.text(title, 40, 76);

            // Handle Date Range
            doc.setFontSize(14);

            if (formattedStartDate && formattedEndDate) {
                // For reports with date range
                doc.text("Report Time Period From", 40, 120);
                doc.text(formattedStartDate, 220, 120);
                doc.text("To", 420, 120);
                doc.text(formattedEndDate, 440, 120);
            } else {
                doc.text("Date Range Not Specified", 40, 120);
            }

            // Horizontal line
            doc.setDrawColor(192);
            doc.line(40, 160, pageWidthInPoints - 40, 160);

            doc.setFontSize(12);
            doc.text(dateTimeString, pageWidthInPoints - 250, 180);

            const tableColumnHeaders = columns.map((col) => col.title);

            const tableData = data.map((row) => {
                return columns.map((col) => {
                    const value = row[col.dataIndex];
                    if (typeof col.render === "function") {
                        return col.render(value, row); // Properly render the value
                    }
                    return Number.isFinite(value) ? value.toLocaleString() : value;
                });
            });

            // Add the table to the PDF
            doc.autoTable({
                head: [tableColumnHeaders],
                body: tableData,
                theme: "striped",
                startY: 210,
                margin: { horizontal: 20 },
                styles: { overflow: "linebreak" },
                tableWidth: "auto",
                headStyles: { fillColor: [0, 175, 80], textColor: [255, 255, 255] },
            });

            doc.save(`${filename}.pdf`);
        })
        .catch((error) => {
            console.error("Error converting image:", error);
            message.error("Failed to generate PDF. Please try again.");
        });
};
