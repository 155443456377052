import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Alert,
  Empty,
  Spin,
  Col,
  Row,
  Table,
  message,
  Select,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import { debounce } from "lodash";
import "antd/dist/antd.css";

const { Option } = Select;

const VehicleReport = () => {
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [reportAliasId, setReportAliasId] = useState("");
  const [providerId, setProviderId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [routeId, setRouteId] = useState("");
  const [stationId, setStationId] = useState("");
  const [studentType, setStudentType] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentId, setStudentId] = useState("");
  const [results, setResults] = useState([]);

  // Original and Filtered Options
  const [originalReportAliases, setOriginalReportAliases] = useState([]);
  const [filteredReportAliases, setFilteredReportAliases] = useState([]);
  const [originalProviders, setOriginalProviders] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [originalVehicles, setOriginalVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [originalRoutes, setOriginalRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [originalStations, setOriginalStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [originalStudentTypes, setOriginalStudentTypes] = useState([]);
  const [filteredStudentTypes, setFilteredStudentTypes] = useState([]);
  const [originalStudentClasses, setOriginalStudentClasses] = useState([]);
  const [filteredStudentClasses, setFilteredStudentClasses] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  // Fetch token and base URL on component mount
  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  const extractFilterOptions = (data) => {
    console.log("API Data:", data); 
    if (data && data.length > 0) {
      const uniqueReportAliases = [
        ...new Set(data.map((item) => item.unique_report_id)),
      ];
      const uniqueProviders = [
        ...new Map(
          data.map((item) => [
            item.provider_id,
            { id: item.provider_id, name: item.provider },
          ])
        ).values(),
      ];
      const uniqueVehicles = [
        ...new Map(
          data.map((item) => [
            item.vehicle_id,
            { id: item.vehicle_id, name: item.Vehicle },
          ])
        ).values(),
      ];
      const uniqueRoutes = [
        ...new Map(
          data.map((item) => [
            item.RouteId,
            { id: item.RouteId, name: item.route_name },
          ])
        ).values(),
      ];
      const uniqueStations = [
        ...new Map(
          data.map((item) => [
            item.StationId,
            { id: item.StationId, name: item.station_name },
          ])
        ).values(),
      ];
      const uniqueStudentTypes = [...new Set(data.map((item) => item.Type))];
      const uniqueStudentClasses = [
        ...new Set(data.map((item) => item.student_class)),
      ];
      const uniqueStudents = [
        ...new Map(
          data.map((item) => [
            item.StudentId,
            { id: item.StudentId, name: item.Name },
          ])
        ).values(),
      ];

      // Set original and filtered states
      setOriginalReportAliases(uniqueReportAliases);
      setFilteredReportAliases(uniqueReportAliases);
      setOriginalProviders(uniqueProviders);
      setFilteredProviders(uniqueProviders);
      setOriginalVehicles(uniqueVehicles);
      setFilteredVehicles(uniqueVehicles);
      setOriginalRoutes(uniqueRoutes);
      setFilteredRoutes(uniqueRoutes);
      setOriginalStations(uniqueStations);
      setFilteredStations(uniqueStations);
      setOriginalStudentTypes(uniqueStudentTypes);
      setFilteredStudentTypes(uniqueStudentTypes);
      setOriginalStudentClasses(uniqueStudentClasses);
      setFilteredStudentClasses(uniqueStudentClasses);
      setOriginalStudents(uniqueStudents);
      setFilteredStudents(uniqueStudents);

      console.log("Filter Options:", {
        reportAliases: uniqueReportAliases,
        providers: uniqueProviders,
        vehicles: uniqueVehicles,
        routes: uniqueRoutes,
        stations: uniqueStations,
        studentTypes: uniqueStudentTypes,
        studentClasses: uniqueStudentClasses,
        students: uniqueStudents,
      });
    }
  };

  const handleFilterSearch = (value, filterType) => {
    const filterMap = {
      reportAlias: {
        data: originalReportAliases,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredReportAliases,
      },
      provider: {
        data: originalProviders,
        filterFn: (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredProviders,
      },
      vehicle: {
        data: originalVehicles,
        filterFn: (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredVehicles,
      },
      route: {
        data: originalRoutes,
        filterFn: (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredRoutes,
      },
      station: {
        data: originalStations,
        filterFn: (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStations,
      },
      studentType: {
        data: originalStudentTypes,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudentTypes,
      },
      studentClass: {
        data: originalStudentClasses,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudentClasses,
      },
      student: {
        data: originalStudents,
        filterFn: (item) =>
          item.name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudents,
      },
    };

    const { data, filterFn, setter } = filterMap[filterType] || {};
    if (!data) return;

    if (!value) {
      setter(data); // Reset to original data if search value is empty
    } else {
      const filtered = data.filter(filterFn);
      setter(filtered);
    }
  };

  // Debounced search handler
  const debouncedSearch = debounce(handleFilterSearch, 300);

  // Generate report
  const generateReport = useCallback(
    async (page = currentPage, limit = pageSize) => {
      if (!token) {
        setError("Missing authentication token. Please log in again.");
        message.error("Missing authentication token. Please log in again.");
        return;
      }

      setBusy(true);
      setText("Generating Report...");
      setError("");

      try {
        const url = `${baseUrl}/fastapi/vehicle/report/`;
        const params = {
          database_name: "",
          unique_report_id: reportAliasId,
          provider_id: providerId,
          vehicle_id: vehicleId,
          route_id: routeId,
          station_id: stationId,
          student_type: studentType,
          student_class: studentClass,
          student_id: studentId,
          page,
          per_page: limit,
        };

        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        const { data } = await axios.get(url, { params, headers });

        if (!data || !data.data || data.data.length === 0) {
          setError("No record matching the filter");
          message.info("No record matching the filter");
          setResults([]);
          setCurrentPage(1);
          setPageSize(limit);
          setTotalItems(0);
          setLastPage(1);
          return;
        }

        setResults(data.data || []);
        setCurrentPage(data.pagination?.current_page || 1);
        setPageSize(data.pagination?.per_page || limit);
        setTotalItems(data.pagination?.total || 0);
        setLastPage(data.pagination?.last_page || 1);

        // Extract filter options from the response
        extractFilterOptions(data.data);
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        setError(`Error fetching data: ${errorMessage}`);
        message.error(`Error fetching data: ${errorMessage}`);
      } finally {
        setBusy(false);
        setText("Generate Report");
      }
    },
    [
      token,
      baseUrl,
      reportAliasId,
      providerId,
      vehicleId,
      routeId,
      stationId,
      studentType,
      studentClass,
      studentId,
      currentPage,
      pageSize,
    ]
  );

  // Clear all filters
  const clearFilters = () => {
    setReportAliasId("");
    setProviderId("");
    setVehicleId("");
    setRouteId("");
    setStationId("");
    setStudentType("");
    setStudentClass("");
    setStudentId("");
  };

  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const exportCurrentPageExcel = () => {
    const augmentedData = getExportData(results, false);
    exportToExcel(augmentedData, "CurrentPageData");
    message.success("Current page exported successfully as Excel.");
  };

  const exportCurrentPageCSV = () => {
    const augmentedData = getExportData(results, false);
    exportToCSV(augmentedData, "CurrentPageData");
    message.success("Current page exported successfully as CSV.");
  };

  const exportCurrentPagePDF = () => {
    const augmentedData = getExportData(results, false);
    exportToPDF(
      exportColumns,
      augmentedData,
      "CurrentPageData",
      "Current Page Data Report",
      [0, 0, 255],
      "/img/go-green.png"
    );
    message.success("Current page exported successfully as PDF.");
  };

  const fetchAllData = async () => {
    setBusy(true);
    let allData = [];
    try {
      for (let page = 1; page <= lastPage; page++) {
        const url = `${baseUrl}/fastapi/vehicle/report/`;
        const params = {
          database_name: "",
          unique_report_id: reportAliasId,
          provider_id: providerId,
          vehicle_id: vehicleId,
          route_id: routeId,
          station_id: stationId,
          student_type: studentType,
          student_class: studentClass,
          student_id: studentId,
          page,
          per_page: pageSize,
        };

        const res = await axios.get(url, {
          params,
          headers: { Authorization: `Bearer ${token}` },
        });
        allData = allData.concat(res.data.data);
      }

      return allData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching all pages: ${errorMessage}`);
      message.error(`Error fetching all pages: ${errorMessage}`);
      return null;
    } finally {
      setBusy(false);
    }
  };

  const exportAllPagesExcel = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToExcel(augmentedData, "AllPagesData");
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesCSV = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToCSV(augmentedData, "AllPagesData");
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesPDF = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        exportColumns,
        augmentedData,
        "AllPagesData",
        "All Pages Data Report",
        [0, 0, 255],
        "/img/go-green.png"
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportColumns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 50,
    },
    {
      title: "unique_report_id",
      dataIndex: "unique_report_id",
      key: "unique_report_id",
    },

    { title: "Adm", dataIndex: "Adm", key: "Adm" },
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Class", dataIndex: "student_class", key: "student_class" },
    { title: "Vehicle", dataIndex: "Vehicle", key: "Vehicle" },
    { title: "Provider", dataIndex: "provider", key: "provider" },
    { title: 'Station', dataIndex: "station_name", key: "station_name" },
    { title: "Route", dataIndex: "route_name", key: "route_name" },
    { title: "To School Schedules",dataIndex: "ToSchoolSchedules",key: "ToSchoolSchedules" },
    {
      title: "From School Schedules",
      dataIndex: "FromSchoolSchedules",
      key: "FromSchoolSchedules",
    },
    {
      title: "Trips To School",
      dataIndex: "Trips_ToSchoolCount",
      key: "Trips_ToSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Trips From School",
      dataIndex: "Trips_FromSchoolCount",
      key: "Trips_FromSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    { title: 'Total Trips', dataIndex: "Total_trips_count", key: "Total_trips_count" },
    {
      title: "Distinct Days",
      dataIndex: "ToFrom_School_Distinct_Days_all",
      key: "ToFrom_School_Distinct_Days_all",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    { title: "Subscription", dataIndex: "Subscription", key: "Subscription" },
    {
      title: "Termly Cost",
      dataIndex: "Cost",
      key: "Cost",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Cost (Trips)",
      dataIndex: "PerVehicleCost_BasedOnTrips",
      key: "PerVehicleCost_BasedOnTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Cost (Schedules)",
      dataIndex: "PerVehicleCost_BasedOnSchedules",
      key: "PerVehicleCost_BasedOnSchedules",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
  ];

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    ...exportColumns.slice(1),
  ];

  const dropdownStyle = {
    backgroundColor: "#ffffff",
    color: "#000000",
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>Vehicle Report</h4>

      <div style={{ width: "80%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          {/* Report Alias Filter */}
          <Col span={6}>
            <label htmlFor="reportAlias">Report Alias</label>
            <Select
              placeholder="Select Report Alias"
              value={reportAliasId}
              onChange={(value) => setReportAliasId(value)}
              onSearch={(value) => debouncedSearch(value, "reportAlias")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredReportAliases.map((alias) => (
                <Option key={alias} value={alias}>
                  {alias}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Provider Filter */}
          <Col span={6}>
            <label htmlFor="provider">Provider</label>
            <Select
              placeholder="Select Provider"
              value={providerId}
              onChange={(value) => setProviderId(value)}
              onSearch={(value) => debouncedSearch(value, "provider")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredProviders.map((provider) => (
                <Option key={provider.id} value={provider.id}>
                  {provider.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Vehicle Filter */}
          <Col span={6}>
            <label htmlFor="vehicle">Vehicle</label>
            <Select
              placeholder="Select Vehicle"
              value={vehicleId}
              onChange={(value) => setVehicleId(value)}
              onSearch={(value) => debouncedSearch(value, "vehicle")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredVehicles.map((vehicle) => (
                <Option key={vehicle.id} value={vehicle.id}>
                  {vehicle.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Route Filter */}
          <Col span={6}>
            <label htmlFor="route">Route</label>
            <Select
              placeholder="Select Route"
              value={routeId}
              onChange={(value) => setRouteId(value)}
              onSearch={(value) => debouncedSearch(value, "route")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredRoutes.map((route) => (
                <Option key={route.id} value={route.id}>
                  {route.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Station Filter */}
          <Col span={6}>
            <label htmlFor="station">Station</label>
            <Select
              placeholder="Select Station"
              value={stationId}
              onChange={(value) => setStationId(value)}
              onSearch={(value) => debouncedSearch(value, "station")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStations.map((station) => (
                <Option key={station.id} value={station.id}>
                  {station.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Type Filter */}
          <Col span={6}>
            <label htmlFor="studentType">Student Type</label>
            <Select
              placeholder="Select Student Type"
              value={studentType}
              onChange={(value) => setStudentType(value)}
              onSearch={(value) => debouncedSearch(value, "studentType")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudentTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Class Filter */}
          <Col span={6}>
            <label htmlFor="studentClass">Student Class</label>
            <Select
              placeholder="Select Student Class"
              value={studentClass}
              onChange={(value) => setStudentClass(value)}
              onSearch={(value) => debouncedSearch(value, "studentClass")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudentClasses.map((studentClass) => (
                <Option key={studentClass} value={studentClass}>
                  {studentClass}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Filter */}
          <Col span={6}>
            <label htmlFor="student">Student</label>
            <Select
              placeholder="Select Student"
              value={studentId}
              onChange={(value) => setStudentId(value)}
              onSearch={(value) => debouncedSearch(value, "student")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudents.map((student) => (
                <Option key={student.id} value={student.id}>
                  {student.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Generate Report Button */}
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>

          {/* Clear Filters Button */}
          <Col span={4}>
            <Button
              type="default"
              onClick={clearFilters}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>
      </div>

      <hr />

      {error && <Alert message={error} type="error" showIcon />}

      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={exportCurrentPageExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPageCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPagePDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (PDF)
                </Button>

                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (PDF)
                </Button>
              </Col>
            </Row>

            <Table
              columns={tableColumns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              rowKey="id"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default VehicleReport;
