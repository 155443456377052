import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  Button,
  Alert,
  Empty,
  Spin,
  Col,
  Row,
  Table,
  message,
  Select,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import { debounce } from "lodash";
import "antd/dist/antd.css";

const { Option } = Select;

const StudentReport = () => {
  const getDate365DaysAgo = () => {
    const date = new Date();
    const pastDate = new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000);
    return pastDate.toISOString().split("T")[0];
  };

  // State declarations
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(getDate365DaysAgo());
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [providerId, setProviderId] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");
  const [routeId, setRouteId] = useState("");
  const [stationId, setStationId] = useState("");
  const [studentType, setStudentType] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentId, setStudentId] = useState("");
  const [results, setResults] = useState([]);

  // Original and Filtered Options
  const [originalUniqueReportIds, setOriginalUniqueReportIds] = useState([]);
  const [filteredUniqueReportIds, setFilteredUniqueReportIds] = useState([]);
  const [originalRoutes, setOriginalRoutes] = useState([]);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [originalStations, setOriginalStations] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const [originalStudentTypes, setOriginalStudentTypes] = useState([]);
  const [filteredStudentTypes, setFilteredStudentTypes] = useState([]);
  const [originalStudentClasses, setOriginalStudentClasses] = useState([]);
  const [filteredStudentClasses, setFilteredStudentClasses] = useState([]);
  const [originalStudents, setOriginalStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  // Fetch token and base URL on component mount
  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account?.access_token) {
      setToken(account.access_token);
    } else {
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  // Generic Search Handler
  const handleFilterSearch = (value, filterType) => {
    const filterMap = {
      uniqueReportId: {
        data: originalUniqueReportIds,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredUniqueReportIds,
      },
      route: {
        data: originalRoutes,
        filterFn: (item) =>
          item.Name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredRoutes,
      },
      station: {
        data: originalStations,
        filterFn: (item) =>
          item.Name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStations,
      },
      studentType: {
        data: originalStudentTypes,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudentTypes,
      },
      studentClass: {
        data: originalStudentClasses,
        filterFn: (item) => item.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudentClasses,
      },
      student: {
        data: originalStudents,
        filterFn: (item) =>
          item.Name.toLowerCase().includes(value.toLowerCase()),
        setter: setFilteredStudents,
      },
    };

    const { data, filterFn, setter } = filterMap[filterType] || {};
    if (!data) return;

    if (!value) {
      setter(data); // Reset to original data if search value is empty
    } else {
      const filtered = data.filter(filterFn);
      setter(filtered);
    }
  };

  // Debounced search handler
  const debouncedSearch = debounce(handleFilterSearch, 300);

  // Generate report
  const generateReport = useCallback(
    async (page = currentPage, limit = pageSize) => {
      if (!token) {
        message.error("Missing authentication token. Please log in again.");
        return;
      }

      setBusy(true);
      setText("Generating Report...");
      setError("");

      try {
        const { data } = await axios.get(`${baseUrl}/fastapi/student/report/`, {
          params: {
            database_name: "",
            unique_report_id: uniqueReportId,
            provider_id: providerId,
            vehicle_id: vehicleId,
            route_id: routeId,
            station_id: stationId,
            student_type: studentType,
            student_class: studentClass,
            student_id: studentId,
            start_date: startDate,
            end_date: endDate,
            page,
            per_page: limit,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!data || !data.data || data.data.length === 0) {
          setError("No record matching the filter");
          message.info("No record matching the filter");
          setResults([]);
          setCurrentPage(1);
          setPageSize(limit);
          setTotalItems(0);
          setLastPage(1);
          return;
        }

        // Update results and pagination
        setResults(data.data || []);
        setCurrentPage(data.pagination?.current_page || 1);
        setPageSize(data.pagination?.per_page || limit);
        setTotalItems(data.pagination?.total || 0);
        setLastPage(data.pagination?.last_page || 1);

        // Update filter options
        setOriginalUniqueReportIds([
          ...new Set(data.data.map((item) => item.unique_report_id)),
        ]);
        setFilteredUniqueReportIds([
          ...new Set(data.data.map((item) => item.unique_report_id)),
        ]);
        setOriginalRoutes([
          ...new Map(
            data.data
              .filter((item) => item.RouteId)
              .map((item) => [
                item.RouteId,
                { id: item.RouteId, Name: item.route_name },
              ])
          ).values(),
        ]);
        setFilteredRoutes([
          ...new Map(
            data.data
              .filter((item) => item.RouteId)
              .map((item) => [
                item.RouteId,
                { id: item.RouteId, Name: item.route_name },
              ])
          ).values(),
        ]);
        setOriginalStations([
          ...new Map(
            data.data
              .filter((item) => item.StationId)
              .map((item) => [
                item.StationId,
                { id: item.StationId, Name: item.station_name },
              ])
          ).values(),
        ]);
        setFilteredStations([
          ...new Map(
            data.data
              .filter((item) => item.StationId)
              .map((item) => [
                item.StationId,
                { id: item.StationId, Name: item.station_name },
              ])
          ).values(),
        ]);
        setOriginalStudentTypes([
          ...new Set(data.data.map((item) => item.Type)),
        ]);
        setFilteredStudentTypes([
          ...new Set(data.data.map((item) => item.Type)),
        ]);
        setOriginalStudentClasses([
          ...new Set(data.data.map((item) => item.student_class)),
        ]);
        setFilteredStudentClasses([
          ...new Set(data.data.map((item) => item.student_class)),
        ]);
        setOriginalStudents([
          ...new Map(
            data.data.map((item) => [
              item.StudentId,
              { StudentId: item.StudentId, Name: item.Name },
            ])
          ).values(),
        ]);
        setFilteredStudents([
          ...new Map(
            data.data.map((item) => [
              item.StudentId,
              { StudentId: item.StudentId, Name: item.Name },
            ])
          ).values(),
        ]);
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        setError(`Error fetching data: ${errorMessage}`);
        message.error(`Error fetching data: ${errorMessage}`);
      } finally {
        setBusy(false);
        setText("Generate Report");
      }
    },
    [
      token,
      baseUrl,
      uniqueReportId,
      providerId,
      vehicleId,
      routeId,
      stationId,
      studentType,
      studentClass,
      studentId,
      startDate,
      endDate,
      currentPage,
      pageSize,
    ]
  );

  // Clear all filters
  const clearFilters = () => {
    setStudentType("");
    setStudentClass("");
    setStudentId("");
    setUniqueReportId("");
    setProviderId("");
    setVehicleId("");
    setRouteId("");
    setStationId("");
    setStartDate(getDate365DaysAgo());
    setEndDate(new Date().toISOString().split("T")[0]);
  };

  // Export functions (same as before)
  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const exportCurrentPageExcel = () => {
    const augmentedData = getExportData(results, false);
    exportToExcel(augmentedData, "CurrentPageData", startDate, endDate);
    message.success("Current page exported successfully as Excel.");
  };

  const exportCurrentPageCSV = () => {
    const augmentedData = getExportData(results, false);
    exportToCSV(augmentedData, "CurrentPageData", startDate, endDate);
    message.success("Current page exported successfully as CSV.");
  };

  const exportCurrentPagePDF = () => {
    const augmentedData = getExportData(results, false);
    exportToPDF(
      exportColumns,
      augmentedData,
      "CurrentPageData",
      "Current Page Data Report",
      [0, 0, 255],
      "/img/go-green.png",
      startDate,
      endDate
    );
    message.success("Current page exported successfully as PDF.");
  };

  const fetchAllData = async () => {
    setBusy(true);
    let allData = [];
    try {
      for (let page = 1; page <= lastPage; page++) {
        const url = `${baseUrl}/fastapi/student/report/`;
        const params = {
          database_name: "",
          unique_report_id: uniqueReportId,
          provider_id: providerId,
          vehicle_id: vehicleId,
          route_id: routeId,
          station_id: stationId,
          student_type: studentType,
          student_class: studentClass,
          student_id: studentId,
          start_date: startDate,
          end_date: endDate,
          page,
          per_page: pageSize,
        };

        const res = await axios.get(url, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        allData = allData.concat(res.data.data);
      }

      return allData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching all pages: ${errorMessage}`);
      message.error(`Error fetching all pages: ${errorMessage}`);
      return null;
    } finally {
      setBusy(false);
    }
  };

  const exportAllPagesExcel = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToExcel(augmentedData, "AllPagesData", startDate, endDate);
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesCSV = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToCSV(augmentedData, "AllPagesData", startDate, endDate);
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesPDF = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        exportColumns,
        augmentedData,
        "AllPagesData",
        "All Pages Data Report",
        [0, 0, 255],
        "/img/go-green.png",
        startDate,
        endDate
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportColumns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 50,
    },
    { title: "unique_report_id", dataIndex: "unique_report_id", key: "unique_report_id" },
    { title: "Adm", dataIndex: "Adm", key: "Adm" },
    { title: "Name", dataIndex: "Name", key: "Name" },
    { title: "Class", dataIndex: "student_class", key: "student_class" },
    { title: "Type", dataIndex: "Type", key: "Type" },
    { title: "Station", dataIndex: "station_name", key: "station_name" },
    { title: "Route", dataIndex: "route_name", key: "route_name" },
    { title: "ToSchoolSchedules", dataIndex: "ToSchoolSchedules", key: "ToSchoolSchedules" },
    { title: "FromSchoolSchedules", dataIndex: "FromSchoolSchedules", key: "FromSchoolSchedules" },
    { title: 'Total Trips', dataIndex: "Total_trips_count", key: "Total_trips_count" },
    {
      title: "Distinct Days",
      dataIndex: "ToFrom_School_Distinct_Days_all",
      key: "ToFrom_School_Distinct_Days_all",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    { title: "Subscription", dataIndex: "Subscription", key: "Subscription" },
    { title: "Activity", dataIndex: "To_and_FromSchool_billing_data", key: "To_and_FromSchool_billing_data" },
    {
      title: "Termly Cost",
      dataIndex: "Cost",
      key: "Cost",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Cost (Trips)",
      dataIndex: "PerVehicleCost_BasedOnTrips",
      key: "PerVehicleCost_BasedOnTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
    {
      title: "Cost (Schedules)",
      dataIndex: "PerVehicleCost_BasedOnSchedules",
      key: "PerVehicleCost_BasedOnSchedules",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "0"),
    },
  ];

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    ...exportColumns.slice(1),
  ];

  const dropdownStyle = {
    backgroundColor: "#ffffff",
    color: "#000000",
  };

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>Student Report</h4>

      <div style={{ width: "80%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          {/* Unique Report ID Filter */}
          <Col span={6}>
            <label>Unique Report ID</label>
            <Select
              placeholder="Select Report ID"
              value={uniqueReportId}
              onChange={setUniqueReportId}
              onSearch={(value) => debouncedSearch(value, "uniqueReportId")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredUniqueReportIds.map((id) => (
                <Option key={id} value={id}>
                  {id}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Route Filter */}
          <Col span={6}>
            <label>Route</label>
            <Select
              placeholder="Select Route"
              value={routeId}
              onChange={setRouteId}
              onSearch={(value) => debouncedSearch(value, "route")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredRoutes.map((route) => (
                <Option key={route.id} value={route.id}>
                  {route.Name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Station Filter */}
          <Col span={6}>
            <label>Station</label>
            <Select
              placeholder="Select Station"
              value={stationId}
              onChange={setStationId}
              onSearch={(value) => debouncedSearch(value, "station")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStations.map((station) => (
                <Option key={station.id} value={station.id}>
                  {station.Name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Type Filter */}
          <Col span={6}>
            <label>Student Type</label>
            <Select
              placeholder="Select Type"
              value={studentType}
              onChange={setStudentType}
              onSearch={(value) => debouncedSearch(value, "studentType")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudentTypes.map((type) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Class Filter */}
          <Col span={6}>
            <label>Student Class</label>
            <Select
              placeholder="Select Class"
              value={studentClass}
              onChange={setStudentClass}
              onSearch={(value) => debouncedSearch(value, "studentClass")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudentClasses.map((cls) => (
                <Option key={cls} value={cls}>
                  {cls}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Student Filter */}
          <Col span={6}>
            <label>Student</label>
            <Select
              placeholder="Select Student"
              value={studentId}
              onChange={setStudentId}
              onSearch={(value) => debouncedSearch(value, "student")}
              showSearch
              filterOption={false}
              style={{ width: "100%" }}
              dropdownStyle={dropdownStyle}
            >
              {filteredStudents.map((student) => (
                <Option key={student.StudentId} value={student.StudentId}>
                  {student.Name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Clear Filters Button */}
          <Col span={6}>
            <Button
              type="default"
              onClick={clearFilters}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Clear Filters
            </Button>
          </Col>

          {/* Generate Report Button */}
          <Col span={4}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>

      <hr />

      {error && <Alert message={error} type="error" showIcon />}

      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={exportCurrentPageExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPageCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPagePDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (PDF)
                </Button>

                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (PDF)
                </Button>
              </Col>
            </Row>

            <Table
              columns={tableColumns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              rowKey="id"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default StudentReport;
