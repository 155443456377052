import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Alert,
  Empty,
  Spin,
  Col,
  Row,
  Table,
  message,
  Select,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const VehicleSummary = () => {
  // Component State
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Filter Report");
  const [error, setError] = useState("");
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [provider, setProvider] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");
  const [results, setResults] = useState([]);

  // Filter Options State
  const [vehicles, setVehicles] = useState([]);
  const [providers, setProviders] = useState([]);
  const [uniqueReportIds, setUniqueReportIds] = useState([]);

  // Filtered Options State
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [filteredUniqueReportIds, setFilteredUniqueReportIds] = useState([]);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  const fetchFilterOptions = (data) => {
    if (data && data.data) {
      const vehicles = [
        ...new Set(
          data.data.map((item) => ({
            id: item.vehicle_id,
            name: item.Vehicle,
          }))
        ),
      ];
      const providers = [
        ...new Set(
          data.data.map((item) => ({
            id: item.provider_id,
            name: item.provider,
          }))
        ),
      ];
      const uniqueReportIds = [
        ...new Set(data.data.map((item) => item.unique_report_id)),
      ];

      console.log("Vehicles:", vehicles);
      console.log("Providers:", providers);
      console.log("Unique Report IDs:", uniqueReportIds);

      setVehicles(vehicles);
      setProviders(providers);
      setUniqueReportIds(uniqueReportIds);

      // Initialize filtered options
      setFilteredVehicles(vehicles);
      setFilteredProviders(providers);
      setFilteredUniqueReportIds(uniqueReportIds);
    }
  };

  const handleFilterSearch = (value, filterType) => {
    switch (filterType) {
      case "vehicle":
        setFilteredVehicles(
          vehicles.filter((vehicle) =>
            vehicle.name.toLowerCase().includes(value.toLowerCase())
          )
        );
        break;

      case "provider":
        setFilteredProviders(
          providers.filter((provider) =>
            provider.name.toLowerCase().includes(value.toLowerCase())
          )
        );
        break;

      case "uniqueReportId":
        setFilteredUniqueReportIds(
          uniqueReportIds.filter((id) =>
            id.toLowerCase().includes(value.toLowerCase())
          )
        );
        break;

      default:
        break;
    }
  };

  const generateReport = async (page = currentPage, limit = pageSize) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");

    try {
      const url =
        `${baseUrl}/fastapi/vehicle/summary` +
        `?database_name=` +
        `&unique_report_id=${uniqueReportId}` +
        `&provider_id=${provider}` +
        `&vehicle_id=${vehicle}` +
        `&page=${page}` +
        `&limit=${limit}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      setResults(data.data || []);
      setCurrentPage(data.pagination?.current_page || 1);
      setPageSize(data.pagination?.per_page || limit);
      setTotalItems(data.pagination?.total || 0);
      setLastPage(data.pagination?.last_page || 1);

      fetchFilterOptions(data);
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
      message.error(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const exportCurrentPageExcel = () => {
    const augmentedData = getExportData(results, false);
    exportToExcel(augmentedData, "CurrentPageData");
    message.success("Current page exported successfully as Excel.");
  };

  const exportCurrentPageCSV = () => {
    const augmentedData = getExportData(results, false);
    exportToCSV(augmentedData, "CurrentPageData");
    message.success("Current page exported successfully as CSV.");
  };

  const exportCurrentPagePDF = () => {
    const augmentedData = getExportData(results, false);
    exportToPDF(
      exportColumns,
      augmentedData,
      "CurrentPageData",
      "Current Page Data Report",
      [0, 0, 255],
      "/img/go-green.png"
    );
    message.success("Current page exported successfully as PDF.");
  };

  const fetchAllData = async () => {
    setBusy(true);
    let allData = [];
    try {
      for (let page = 1; page <= lastPage; page++) {
        const url =
          `${baseUrl}/fastapi/vehicle/summary` +
          `?database_name=` +
          `&unique_report_id=${uniqueReportId}` +
          `&provider_id=${provider}` +
          `&vehicle_id=${vehicle}` +
          `&page=${page}` +
          `&limit=${pageSize}`;

        const res = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        allData = allData.concat(res.data.data);
      }

      return allData;
    } catch (error) {
      setError(
        `Error fetching all pages: ${
          error.response?.data?.message || error.message
        }`
      );
      message.error(
        `Error fetching all pages: ${
          error.response?.data?.message || error.message
        }`
      );
      return null;
    } finally {
      setBusy(false);
    }
  };

  const exportAllPagesExcel = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToExcel(augmentedData, "AllPagesData");
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesCSV = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToCSV(augmentedData, "AllPagesData");
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportAllPagesPDF = async () => {
    setExporting(true);
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        exportColumns,
        augmentedData,
        "AllPagesData",
        "All Pages Data Report",
        [0, 0, 255],
        "/img/go-green.png"
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
    setExporting(false);
  };

  const exportColumns = [
    {
      title: "S.No",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 50,
    },
    {
      title: "Unique Report ID",
      dataIndex: "unique_report_id",
      key: "unique_report_id",
    },
    {
      title: "Vehicle",
      dataIndex: "Vehicle",
      key: "Vehicle",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "No. of students",
      dataIndex: "number_of_students",
      key: "number_of_students",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "No. Trips To School",
      dataIndex: "trips_ToSchoolCount",
      key: "trips_ToSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "No. Trips From School",
      dataIndex: "trips_FromSchoolCount",
      key: "trips_FromSchoolCount",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
    title: "Total Trips",
    dataIndex: "total_trips_count",
    key: "total_trips_count",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Cost (Trips)",
      dataIndex: "perVehicleCost_BasedOnTrips",
      key: "perVehicleCost_BasedOnTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Trips %",
      dataIndex: "Based_On_Trips_Percantage",
      key: "Based_On_Trips_Percantage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Trips Less %",
      dataIndex: "Based_On_Trips_Sum_less_Percentage",
      key: "Based_On_Trips_Sum_less_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Cost (Schedules)",
      dataIndex: "perVehicleCost_BasedOnSchedules",
      key: "perVehicleCost_BasedOnSchedules",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Schedules %",
      dataIndex: "Based_On_Schedules_Percentage",
      key: "Based_On_Schedules_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Schedules Less %",
      dataIndex: "Based_On_Schedules_Sum_less_Percentage",
      key: "Based_On_Schedules_Sum_less_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
  ];

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    ...exportColumns.slice(1),
  ];

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Vehicle Summary Report
      </h4>

      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          {/* Vehicle Dropdown */}
          <Col span={12}>
            <label>Vehicle</label>
            <Select
              showSearch
              placeholder="Select Vehicle"
              value={vehicle}
              onChange={(value) => setVehicle(value)}
              onSearch={(value) => handleFilterSearch(value, "vehicle")}
              filterOption={false}
              style={{ width: "100%" }}
              notFoundContent="No vehicles found"
            >
              {filteredVehicles.map((vehicle) => (
                <Option key={vehicle.id} value={vehicle.id}>
                  {vehicle.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Provider Dropdown */}
          <Col span={12}>
            <label>Provider</label>
            <Select
              showSearch
              placeholder="Select Provider"
              value={provider}
              onChange={(value) => setProvider(value)}
              onSearch={(value) => handleFilterSearch(value, "provider")}
              filterOption={false}
              style={{ width: "100%" }}
              notFoundContent="No providers found"
            >
              {filteredProviders.map((provider) => (
                <Option key={provider.id} value={provider.id}>
                  {provider.name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Unique Report ID Dropdown */}
          <Col span={12}>
            <label>Unique Report ID</label>
            <Select
              showSearch
              placeholder="Select Unique Report ID"
              value={uniqueReportId}
              onChange={setUniqueReportId}
              onSearch={(value) => handleFilterSearch(value, "uniqueReportId")}
              filterOption={false}
              style={{ width: "100%" }}
            >
              {filteredUniqueReportIds.map((id) => (
                <Option key={id} value={id}>
                  {id}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Clear Filters Button */}
          <Col span={12}>
            <Button
              type="default"
              onClick={() => {
                setVehicle("");
                setProvider("");
                setUniqueReportId("");
              }}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Clear Filters
            </Button>
          </Col>

          {/* Generate Report Button */}
          <Col span={12}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>

      <hr />

      {error && <Alert message={error} type="error" showIcon />}

      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={exportCurrentPageExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPageCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={exportCurrentPagePDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (PDF)
                </Button>

                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (PDF)
                </Button>
              </Col>
            </Row>

            <Table
              columns={tableColumns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              rowKey="id"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default VehicleSummary;
