import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  Button,
  Alert,
  Select,
  Empty,
  Spin,
  Col,
  Row,
  message,
} from "antd";
import {
  exportToExcel,
  exportToCSV,
  exportToPDF,
} from "../../utils/generateReports";
import "antd/dist/antd.css";

const { Option } = Select;

const ProviderSummary = () => {
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Generate Report");
  const [results, setResults] = useState([]);
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString("en-CA")
  );
  const [token, setToken] = useState("");
  const [providers, setProviders] = useState([]);
  const [baseUrl, setBaseUrl] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");
  const [uniqueReportIds, setUniqueReportIds] = useState([]);
  const [filteredUniqueReportIds, setFilteredUniqueReportIds] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  // Define columns dynamically based on the payload
  const columns = [
    {
      title: "#",
      key: "index",
      width: 50,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: "Report Alias",
      dataIndex: "unique_report_id",
      key: "unique_report_id",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "No. of Vehicles",
      dataIndex: "number_of_vehicles",
      key: "number_of_vehicles",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "No. of Students",
      dataIndex: "number_of_students",
      key: "number_of_students",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Total Trips",
      dataIndex: "total_trips_count",
      key: "total_trips_count",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Cost (Trips)",
      dataIndex: "perVehicleCost_BasedOnTrips",
      key: "perVehicleCost_BasedOnTrips",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Trips %",
      dataIndex: "Based_On_Trips_Percantage",
      key: "Based_On_Trips_Percantage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Trips Less %",
      dataIndex: "Based_On_Trips_Sum_less_Percentage",
      key: "Based_On_Trips_Sum_less_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Cost (Schedules)",
      dataIndex: "perVehicleCost_BasedOnSchedules",
      key: "perVehicleCost_BasedOnSchedules",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Schedules %",
      dataIndex: "Based_On_Schedules_Percentage",
      key: "Based_On_Schedules_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
    {
      title: "Schedules Less %)",
      dataIndex: "Based_On_Schedules_Sum_less_Percentage",
      key: "Based_On_Schedules_Sum_less_Percentage",
      render: (text) => (Number.isFinite(text) ? text.toLocaleString() : "N/A"),
    },
  ];

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  const generateReport = async (page = currentPage, limit = pageSize) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");

    try {
      const url = `${baseUrl}/fastapi/provider/summary`;
      const params = {
        unique_report_id: uniqueReportId,
        provider_id: selectedProvider,
        start_date: startDate,
        end_date: endDate,
        page,
        per_page: limit,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { params, headers });

      if (!data || !data.data || data.data.length === 0) {
        setError("No records found matching the filter criteria.");
        message.info("No records found matching the filter criteria.");
        setResults([]);
        return;
      }

      if (!data || !data.data || data.data.length === 0) {
        setError("No record matching the filter");
        message.info("No record matching the filter");
        setResults([]);
        setCurrentPage(1);
        setPageSize(limit);
        setLastPage(1);
        return;
      }
      // Update state with pagination data
      setResults(data.data || []);
      setCurrentPage(data.pagination?.current_page || 1);
      setPageSize(data.pagination?.per_page || limit);
      setTotalRecords(data.pagination?.total || 0);
      setLastPage(data.pagination?.last_page || 1);

      const uniqueProviders = [
        ...new Map(
          data.data.map((item) => [item.provider_id, item.provider])
        ).values(),
      ].map((provider, index) => ({
        id: index + 1,
        provider_id: data.data.find((item) => item.provider === provider)
          .provider_id,
        provider,
      }));

      setProviders(uniqueProviders);

      const uniqueIds = [
        ...new Set(data.data.map((item) => item.unique_report_id)),
      ];
      setUniqueReportIds(uniqueIds);
      setFilteredUniqueReportIds(uniqueIds);
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching data: ${errorMessage}`);
      message.error(`Error fetching data: ${errorMessage}`);
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  const fetchAllData = async () => {
    setExporting(true);
    let allData = [];
    try {
      for (let page = 1; page <= lastPage; page++) {
        const url = `${baseUrl}/fastapi/provider/summary`;
        const params = {
          unique_report_id: uniqueReportId,
          provider_id: selectedProvider,
          start_date: startDate,
          end_date: endDate,
          page,
          per_page: pageSize,
        };

        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };

        const { data } = await axios.get(url, { params, headers });

        if (data && data.data) {
          allData = allData.concat(data.data);
        }
      }

      return allData;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error fetching all pages: ${errorMessage}`);
      message.error(`Error fetching all pages: ${errorMessage}`);
      return null;
    } finally {
      setExporting(false);
    }
  };

  const getExportData = (data, isAllPages = false) => {
    return data.map((item, index) => ({
      serialNumber: isAllPages
        ? index + 1
        : (currentPage - 1) * pageSize + index + 1,
      ...item,
    }));
  };

  const exportAllPagesExcel = async () => {
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      exportToExcel(allData, "ProviderSummary_AllPages");
      message.success("All pages exported successfully as Excel.");
    } else {
      message.warning("No data available to export.");
    }
  };

  const exportAllPagesCSV = async () => {
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      exportToCSV(allData, "ProviderSummary_AllPages");
      message.success("All pages exported successfully as CSV.");
    } else {
      message.warning("No data available to export.");
    }
  };

  const exportAllPagesPDF = async () => {
    const allData = await fetchAllData();
    if (allData && allData.length > 0) {
      const augmentedData = getExportData(allData, true);
      exportToPDF(
        columns,
        augmentedData,
        "ProviderSummary_AllPages",
        "Provider Summary Report (All Pages)",
        [0, 175, 80],
        "/img/go-green.png"
      );
      message.success("All pages exported successfully as PDF.");
    } else {
      message.warning("No data available to export.");
    }
  };

  const handleUniqueReportIdSearch = (value) => {
    if (!value) {
      setFilteredUniqueReportIds(uniqueReportIds);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = uniqueReportIds.filter((id) =>
        id.toLowerCase().includes(lowercasedValue)
      );
      setFilteredUniqueReportIds(filtered);
    }
  };

  const changeUniqueReportIdHandler = (value) => {
    setUniqueReportId(value);
  };
  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Provider Summary
      </h4>
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label htmlFor="uniqueReportId" style={{ paddingRight: 10 }}>
              Report Alias
            </label>
            <Select
              placeholder="Select report alias"
              value={uniqueReportId}
              onChange={changeUniqueReportIdHandler}
              onSearch={handleUniqueReportIdSearch}
              size="large"
              style={{ width: "100%" }}
              showSearch
              filterOption={false}
            >
              {filteredUniqueReportIds.map((id) => (
                <Option key={id} value={id}>
                  {id}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <label htmlFor="provider" style={{ paddingRight: 10 }}>
              Provider
            </label>
            <Select
              placeholder="Select Provider"
              value={selectedProvider}
              onChange={(value) => setSelectedProvider(value)}
              size="large"
              style={{ width: "100%" }}
            >
              {providers.map((provider) => (
                <Option key={provider.provider_id} value={provider.provider_id}>
                  {provider.provider}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              size="large"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>
      <hr />
      {error && <Alert message={error} type="error" showIcon />}
      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            <Row justify="end" style={{ marginBottom: 20 }}>
              <Col>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToExcel(results, "ProviderSummary_CurrentPage")
                  }
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (Excel)
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToCSV(results, "ProviderSummary_CurrentPage")
                  }
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (CSV)
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    exportToPDF(
                      columns,
                      results,
                      "ProviderSummary_CurrentPage",
                      "Provider Summary Report (Current Page)",
                      [0, 175, 80],
                      "/img/go-green.png"
                    )
                  }
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export Current Page (PDF)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesExcel}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (Excel)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesCSV}
                  style={{ marginRight: 10 }}
                  disabled={exporting}
                >
                  Export All Pages (CSV)
                </Button>
                <Button
                  type="default"
                  onClick={exportAllPagesPDF}
                  disabled={exporting}
                >
                  Export All Pages (PDF)
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize,
                total: totalRecords,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, size) => {
                  setCurrentPage(page);
                  setPageSize(size);
                  generateReport(page, size);
                },
                onShowSizeChange: (current, size) => {
                  setCurrentPage(1);
                  setPageSize(size);
                  generateReport(1, size);
                },
              }}
              rowKey="id"
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>
    </div>
  );
};

export default ProviderSummary;
