import React, { Component } from "react";
import "./App.css";
import AppLayout from "./layout/Layout";
import Checkin from "./layout/Reports/Checkin";
import ProviderDay from "./layout/Reports/ProviderDay";
import AgentDay from "./layout/Reports/AgentDay";
import DriverDay from "./layout/Reports/DriverDay";
import StorageExplorer from "./layout/Reports/StorageExplorer";
import Audit from "./layout/Reports/Audit";
import Invoice from "./layout/Reports/Invoice";
import { exists, get, put } from "./utils/Storage";
import { notification } from "antd";
import axios from "axios";
import Route from "./utils/Routing";
import PasswordResetPage from "./layout/pages/Security/PasswordResetPage";
import GoSync from "./layout/pages/Sync/GoSync";
import Verify from "./layout/pages/Security/Verify";
import AuthPermissions from "./layout/pages/Security/AuthPermissions";
import Lock from "./layout/pages/Lock";
import auth from "./Components/Auth";
import GoServer from "./Server/GoServer";
import Go from "./Server/Go";
import Env from "./utils/Env";
import StudentType from "./layout/Reports/StudentType";
import StudentTypeSummary from "./layout/Reports/StudentTypeSummary";
import StudentAttendance from "./layout/Reports/StudentAttendance";
import TripSummary from "./layout/Reports/TripSummary";
import VehicleSummary from "./layout/Reports/VehicleSummary";
import VehicleEarnings from "./layout/Reports/VehicleEarnings";
import VehicleTrips from "./layout/Reports/VehicleTrips";
import MissedTrips from "./layout/Reports/MissedTrips";

class App extends Component {
  state = {
    loggedIn: false,
  };

  componentWillMount() {
    if (exists("admin")) {
      this.setState({ loggedIn: true });
    }
  }

  render() {
    let _route = Route.Route();

    if (_route === "reset") return <PasswordResetPage />;

    if (_route === "verify") return <Verify />;

    if (!this.state.loggedIn) {
      window.location = "/auth/?" + btoa(axios.defaults.baseURL);
      return;
    }

    let admin = get("admin");
    let user = admin.user;

    if (Env.isDevelopment() && user.providerid != "3") {
      user.providerid = "3";
      admin.user = user;
      put("admin", admin);
    }

    if (Go.getUser().noAdmin() && auth.no(auth.AccessBeta)) {
      return (
        <Lock
          message={"Transporter Panel is under maintenance, Contact admin.."}
        />
      );
    }

    if (_route === "init" || GoServer.isEndPointsBroken()) return <GoSync />;

    if (_route === "permissions") return <AuthPermissions />;

    if (window.location.href.includes("#?report=") && this.state.loggedIn) {
      document.body.style = "background: white;";
      let report = window.gup("report");
      if (report === "moing") {
        return <StorageExplorer />;
      }
      if (report === "audit") {
        return <Audit />;
      }

      if (report === "checkin") {
        return <Checkin />;
      }
      if (report === "provider-d") {
        return <ProviderDay />;
      }
      if (report === "agent-d") {
        return <AgentDay />;
      }
      if (report === "driver-d") {
        return <DriverDay />;
      }
      if (report === "invoice") {
        return <Invoice />;
      }
      if (report === "student-type") {
        return <StudentType />;
      }
      if (report === "student-type-summary") {
        return <StudentTypeSummary />;
      }
      // if (report === "student-activity") {
      //   return <StudentActiv />;
      // }
      if (report === "student-attendance") {
        return <StudentAttendance />;
      }
      if (report === "trip-summary") {
        return <TripSummary />;
      }
      if (report === "vehicle-summary") {
        return <VehicleSummary />;
      }
      if (report === "vehicle-earnings") {
        return <VehicleEarnings />;
      }
      if (report === "vehicle-trips") {
        return <VehicleTrips />;
      }
      if (report === "missed-trips") {
        return <MissedTrips />;
      }
    }

    return <AppLayout />;
  }
}

export default App;
