import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Input, Alert, Row, Col, DatePicker, message } from "antd";
import "antd/dist/antd.css";

const { RangePicker } = DatePicker;

const ReportProcessing = () => {
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");

  const [databaseName, setDatabaseName] = useState("Database");
  const [reportAlias, setReportAlias] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [noTranches, setNoTranches] = useState("");
  const [percentage, setPercentage] = useState("");
  const [toSchoolMinTrips, setToSchoolMinTrips] = useState(1);
  const [toSchoolMinDays, setToSchoolMinDays] = useState(1);
  const [fromSchoolMinTrips, setFromSchoolMinTrips] = useState(1);
  const [fromSchoolMinDays, setFromSchoolMinDays] = useState(1);
  const [toFromSchoolMinTrips, setToFromSchoolMinTrips] = useState("");
  const [toFromSchoolMinDays, setToFromSchoolMinDays] = useState(1);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
      console.log("Base URL set:", BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  const handleSubmit = async () => {
    if (!token || !baseUrl) {
      setError(
        "Missing authentication token or base URL. Please check your settings."
      );
      message.error(
        "Missing authentication token or base URL. Please check your settings."
      );
      return;
    }

    if (
      !reportAlias ||
      !dateRange ||
      dateRange.length !== 2 ||
      !noTranches ||
      !percentage ||
      !toFromSchoolMinTrips
    ) {
      setError("Please fill out all required fields.");
      message.error("Please fill out all required fields.");
      return;
    }

    setBusy(true);
    setError("");
    setSuccess("");

    try {
      const formattedValues = {
        database_name: databaseName,
        report_alias: reportAlias,
        date_start: dateRange[0].toISOString(),
        date_end: dateRange[1].toISOString(),
        no_tranches: noTranches,
        percentage: percentage,
        to_school_min_trips: toSchoolMinTrips,
        to_school_min_days: toSchoolMinDays,
        from_school_min_trips: fromSchoolMinTrips,
        from_school_min_days: fromSchoolMinDays,
        to_from_school_min_trips: toFromSchoolMinTrips,
        to_from_school_min_days: toFromSchoolMinDays,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      console.log("Sending request to:", `${baseUrl}/fastapi/process_report`);

      const response = await axios.post(
        `${baseUrl}/fastapi/process_report`,
        formattedValues,
        { headers }
      );

      if (response.data.status === "success") {
        setSuccess(response.data.message);
        message.success("Report processed successfully!");
      } else {
        setError(response.data.message || "Failed to process report.");
        message.error(response.data.message || "Failed to process report.");
      }
    } catch (error) {
      console.error("Error processing report:", error);
      const errorMessage = error.response?.data?.message || error.message;
      setError(`Error processing report: ${errorMessage}`);
      message.error(`Error processing report: ${errorMessage}`);
    } finally {
      setBusy(false);
    }
  };

  return (
    <div style={{ marginTop: 20, padding: "0 20px" }}>
      <h4 style={{ textAlign: "center", marginBottom: 10 }}>
        Report Processing
      </h4>

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          style={{ marginBottom: 8 }}
        />
      )}

      {success && (
        <Alert
          message={success}
          type="success"
          showIcon
          style={{ marginBottom: 8 }}
        />
      )}

      <Row gutter={[8, 4]} style={{ maxWidth: 800, margin: "0 auto" }}>
        {/* <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Database Name</label>
            <Input
              placeholder="Enter Database Name"
              value={databaseName}
              onChange={(e) => setDatabaseName(e.target.value)}
            />
          </div>
        </Col> */}

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Report Name</label>
            <Input
              placeholder="Enter Report Name"
              value={reportAlias}
              onChange={(e) => setReportAlias(e.target.value)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Number of Tranches</label>
            <Input
              type="number"
              placeholder="Enter Number of Tranches"
              value={noTranches}
              onChange={(e) => setNoTranches(e.target.value)}
            />
          </div>
        </Col>

        <Col span={24}>
          <div style={{ marginBottom: 8 }}>
            <label>Date Range</label>
            <RangePicker
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              style={{ width: "100%" }}
              value={dateRange}
              onChange={(dates) => setDateRange(dates)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>Percentage</label>
            <Input
              type="number"
              step="0.01"
              placeholder="Enter Percentage"
              value={percentage}
              onChange={(e) => setPercentage(e.target.value)}
            />
          </div>
        </Col>

        {/* <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>To School Min Trips</label>
            <Input
              type="number"
              placeholder="Enter Min Trips to School"
              value={toSchoolMinTrips}
              onChange={(e) => setToSchoolMinTrips(e.target.value)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>To School Min Days</label>
            <Input
              type="number"
              placeholder="Enter Min Days to School"
              value={toSchoolMinDays}
              onChange={(e) => setToSchoolMinDays(e.target.value)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>From School Min Trips</label>
            <Input
              type="number"
              placeholder="Enter Min Trips from School"
              value={fromSchoolMinTrips}
              onChange={(e) => setFromSchoolMinTrips(e.target.value)}
            />
          </div>
        </Col>

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>From School Min Days</label>
            <Input
              type="number"
              placeholder="Enter Min Days from School"
              value={fromSchoolMinDays}
              onChange={(e) => setFromSchoolMinDays(e.target.value)}
            />
          </div>
        </Col> */}

        <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>To/From School Min Trips</label>
            <Input
              type="number"
              placeholder="Enter Min Trips to/from School"
              value={toFromSchoolMinTrips}
              onChange={(e) => setToFromSchoolMinTrips(e.target.value)}
            />
          </div>
        </Col>

        {/* <Col span={12}>
          <div style={{ marginBottom: 8 }}>
            <label>To/From School Min Days</label>
            <Input
              type="number"
              placeholder="Enter Min Days to/from School"
              value={toFromSchoolMinDays}
              onChange={(e) => setToFromSchoolMinDays(e.target.value)}
            />
          </div>
        </Col> */}

        <Col span={24}>
          <Button
            type="primary"
            onClick={handleSubmit}
            loading={busy}
            disabled={!token || busy}
            style={{ width: "100%" }}
          >
            Process Report
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ReportProcessing;
