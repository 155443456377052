import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  Button,
  Input,
  Alert,
  Empty,
  Spin,
  Col,
  Row,
  Select,
  Table,
  message,
  Modal,
  Form,
  Dropdown,
  Menu,
} from "antd";
import "antd/dist/antd.css";
const { Option } = Select;

const DistinctReports = () => {
  // Component State
  const [busy, setBusy] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [text, setText] = useState("Filter Report");
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [token, setToken] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [uniqueReportId, setUniqueReportId] = useState("");
  const [reportName, setReportName] = useState("");
  const [results, setResults] = useState([]);
  const [uniqueReportIds, setUniqueReportIds] = useState([]);
  const [reportNames, setReportNames] = useState([]);

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(1);

  // Modal State
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reportAlias, setReportAlias] = useState("");

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account"));
    const BaseUrl = localStorage.getItem("api");

    if (account && account.access_token) {
      setToken(account.access_token);
    } else {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
    }

    if (BaseUrl) {
      setBaseUrl(BaseUrl);
    } else {
      setError("Missing base URL. Please configure your settings.");
      message.error("Missing base URL. Please configure your settings.");
    }
  }, []);

  const generateReport = async (page = currentPage, limit = pageSize) => {
    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    setText("Generating Report...");
    setError("");

    try {
      const url =
        `${baseUrl}/fastapi/distinct_report_type` +
        `?database_name=` +
        `&unique_report_id=${uniqueReportId}` +
        `&report_name=${reportName}` +
        `&start_date=${startDate}` +
        `&end_date=${endDate}` +
        `&page=${page}` +
        `&limit=${limit}`;

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const { data } = await axios.get(url, { headers });

      if (!data || !data.data || data.data.length === 0) {
        setError("No record matching the filter");
        message.info("No record matching the filter");
        setResults([]);
        setCurrentPage(1);
        setPageSize(limit);
        setTotalItems(0);
        setLastPage(1);
        return;
      }

      setResults(data.data || []);
      setCurrentPage(data.pagination.current_page || 1);
      setPageSize(data.pagination.per_page || limit);
      setTotalItems(data.pagination.total || 0);
      setLastPage(data.pagination.last_page || 1);

      // Extract unique IDs and names from the response
      const uniqueIds = [
        ...new Set(data.data.map((item) => item.unique_report_id)),
      ];
      const names = [...new Set(data.data.map((item) => item.report_alias))];

      setUniqueReportIds(uniqueIds);
      setReportNames(names);
    } catch (error) {
      setError(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
      message.error(
        `Error fetching data: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setBusy(false);
      setText("Generate Report");
    }
  };

  const handleUniqueReportIdSearch = (value) => {
    if (!value) {
      setFilteredUniqueReportIds(uniqueReportIds);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = uniqueReportIds.filter((id) =>
        id.toLowerCase().includes(lowercasedValue)
      );
      setFilteredUniqueReportIds(filtered);
    }
  };

  const handleReportNameSearch = (value) => {
    if (!value) {
      setFilteredReportNames(reportNames);
    } else {
      const lowercasedValue = value.toLowerCase();
      const filtered = reportNames.filter((name) =>
        name.toLowerCase().includes(lowercasedValue)
      );
      setFilteredReportNames(filtered);
    }
  };

  const changeUniqueReportIdHandler = (value) => {
    setUniqueReportId(value);
  };

  const changeReportNameHandler = (value) => {
    setReportName(value);
  };

  // State for filtered options
  const [filteredUniqueReportIds, setFilteredUniqueReportIds] =
    useState(uniqueReportIds);
  const [filteredReportNames, setFilteredReportNames] = useState(reportNames);

  // Update filtered options when uniqueReportIds or reportNames change
  useEffect(() => {
    setFilteredUniqueReportIds(uniqueReportIds);
  }, [uniqueReportIds]);

  useEffect(() => {
    setFilteredReportNames(reportNames);
  }, [reportNames]);

  const deleteReport = async (uniqueReportId) => {
    console.log("Deleting report with ID:", uniqueReportId);

    if (!token) {
      setError("Missing authentication token. Please log in again.");
      message.error("Missing authentication token. Please log in again.");
      return;
    }

    setBusy(true);
    try {
      const url = `${baseUrl}/fastapi/delete_report`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const payload = {
        unique_report_id: uniqueReportId,
      };

      const response = await axios.request({
        method: "delete",
        url,
        headers,
        data: payload,
      });

      if (response.data && response.data.detail) {
        if (response.data.detail.includes("No records found")) {
          message.warning(response.data.detail);
        } else {
          message.success(response.data.detail);
        }
      } else {
        message.success("Report deleted successfully.");
      }

      generateReport();
    } catch (error) {
      const errorMessage =
        error.response?.data?.detail ||
        error.response?.data?.message ||
        error.message;
      setError(`Error deleting report: ${errorMessage}`);
      message.error(`Error deleting report: ${errorMessage}`);
    } finally {
      setBusy(false);
    }
  };

  const openUpdateModal = (report) => {
    setSelectedReport(report);
    setReportAlias(report.report_alias);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedReport(null);
    setReportAlias("");
  };

  const updateReportAlias = async () => {
    if (!token || !selectedReport) {
      setError("Missing authentication token or selected report.");
      message.error("Missing authentication token or selected report.");
      return;
    }

    setBusy(true);
    try {
      const url = `${baseUrl}/fastapi/distinct_report_alias_update`;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const payload = {
        database_name: "",
        unique_report_id: selectedReport.unique_report_id,
        report_alias: reportAlias,
      };

      await axios.patch(url, payload, { headers });
      message.success("Report name updated successfully.");
      generateReport();
      handleModalClose();
    } catch (error) {
      setError(
        `Error updating report name: ${
          error.response?.data?.message || error.message
        }`
      );
      message.error(
        `Error updating report name: ${
          error.response?.data?.message || error.message
        }`
      );
    } finally {
      setBusy(false);
    }
  };

  const renderActionsMenu = (record) => (
    <Menu>
      <Menu.Item key="update" onClick={() => openUpdateModal(record)}>
        Update Name
      </Menu.Item>
      <Menu.Item
        key="delete"
        danger
        onClick={() => deleteReport(record.unique_report_id)}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  const tableColumns = [
    {
      title: "#",
      key: "index",
      width: 60,
      render: (text, record, index) => (
        <span>{(currentPage - 1) * pageSize + index + 1}</span>
      ),
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Unique Report ID",
      dataIndex: "unique_report_id",
      key: "unique_report_id",
    },

    {
      title: "Report Name",
      dataIndex: "report_alias",
      key: "report_alias",
    },
    {
      title: "Start Date",
      dataIndex: "date_start",
      key: "date_start",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "End Date",
      dataIndex: "date_end",
      key: "date_end",
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "No. of Tranches",
      dataIndex: "no_tranches",
      key: "no_tranches",
    },
    {
      title: "To_From_School_Min_Trips",
      dataIndex: "to_from_school_min_trips",
      key: "to_from_school_min_trips",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Actions",
      key: "actions",
      width: 100,
      render: (text, record) => (
        <Dropdown overlay={renderActionsMenu(record)} trigger={["click"]}>
          <span style={{ cursor: "pointer" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
              width="25"
              height="25"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z"
              />
            </svg>
          </span>
        </Dropdown>
      ),
    },
  ];

  return (
    <div style={{ marginTop: 30 }}>
      <h4 style={{ textAlign: "center", marginBottom: 20 }}>
        Distinct Reports
      </h4>

      {/* Filter Section */}
      <div style={{ width: "50%", margin: "0 auto", marginTop: 30 }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "40px" }}>
          <Col span={12}>
            <label htmlFor="uniqueReportId">Unique Report ID</label>
            <Select
              placeholder="Select Unique Report ID"
              value={uniqueReportId}
              onChange={changeUniqueReportIdHandler}
              onSearch={handleUniqueReportIdSearch}
              style={{ width: "100%" }}
              showSearch
              filterOption={false} // Disable default filtering since we handle it manually
            >
              {filteredUniqueReportIds.map((id) => (
                <Option key={id} value={id}>
                  {id}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Report Name Dropdown */}
          <Col span={12}>
            <label htmlFor="reportName">Report Name</label>
            <Select
              placeholder="Select Report Name"
              value={reportName}
              onChange={changeReportNameHandler}
              onSearch={handleReportNameSearch}
              style={{ width: "100%" }}
              showSearch
              filterOption={false}
            >
              {filteredReportNames.map((name) => (
                <Option key={name} value={name}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Clear Filters Button */}
          <Col span={12}>
            <Button
              type="default"
              onClick={() => {
                setUniqueReportId("");
                setReportName("");
                setFilteredUniqueReportIds(uniqueReportIds);
                setFilteredReportNames(reportNames);
              }}
              style={{ width: "100%", marginTop: "26px" }}
            >
              Clear Filters
            </Button>
          </Col>

          <Col span={12}>
            <Button
              type="primary"
              onClick={() => generateReport(1, pageSize)}
              loading={busy}
              disabled={!token || busy}
              style={{ width: "100%", marginTop: "26px" }}
            >
              {text}
            </Button>
          </Col>
        </Row>
      </div>

      <hr />

      {error && <Alert message={error} type="error" showIcon />}

      <Spin spinning={busy || exporting}>
        {results.length > 0 ? (
          <div
            style={{
              width: "90%",
              margin: "auto",
              marginTop: "40px",
              overflowX: "auto",
            }}
          >
            {/* Data Table */}
            <Table
              columns={tableColumns}
              dataSource={results}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalItems,
                showSizeChanger: true,
                pageSizeOptions: ["10", "20", "50", "100"],
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                  generateReport(page, pageSize);
                },
              }}
              rowKey="unique_report_id"
              bordered
            />
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: 40 }}>
            <Empty
              description="No data available. Please generate a report."
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
      </Spin>

      <Modal
        title="Update Report Name"
        visible={isModalVisible}
        onCancel={handleModalClose}
        onOk={updateReportAlias}
        confirmLoading={busy}
      >
        <Form layout="vertical">
          <Form.Item label="Report Name">
            <Input
              value={reportAlias}
              onChange={(e) => setReportAlias(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DistinctReports;
